<template>
    <v-dialog max-width="500px"  v-model="show">
    <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <v-card>
          <v-toolbar flat dark color="panel1">
            <v-toolbar-title>Add New Merchant</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="show=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
            <div class="pa-5">

                            <v-row no-gutters>
                    <v-col cols="12">

                <v-form ref="mainForm">
                    <v-text-field 
                        v-model="form.merchant_code" label="Code" :rules="inputRules" 
                        outlined
                        v-if="show" autofocus  
                    ></v-text-field>
                    <v-text-field v-model="form.merchant_name" label="Name" :rules="inputRules" outlined></v-text-field>
                    <v-select
                        v-model = "form.merchant_type_code"
                        :items="merchant_type_list"
                        item-text="description"
                        item-value="code"
                        label="Type"
                        standard
                        :rules="inputRules"
                        outlined
                    ></v-select>                    
                    <v-textarea v-model="form.long_description" label="Description" outlined></v-textarea>
                    
                    <v-spacer></v-spacer>
                </v-form>
                    </v-col>
                            </v-row>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark>mdi-check</v-icon>
                    Submit
                </v-btn>
                <v-btn class="ml-2" color="cbtnRed" dark @click="show=false">
                    <v-icon left dark>mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>

          </v-card>
    </v-dialog>

</template>

<script>
import ApMerchantService from '@/services/ApMerchantService'
import DlgMessage from '@/components/DlgMessage'

export default {
    name: "DlgMerchantAdd",
    props: {
        visible:Boolean,
    },      
    components:{DlgMessage},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
                this.$emit('close')
           }
        }
      },
      
    },
    data(){
        return{
            dialog: false,
            form:{
                login_id: "",
                merchant_name: "",
                merchant_code: "",
                merchant_type_code: "1",
                logo_file_name: "",
                display_pic1: "",
                display_pic2: "",
                display_pic3: "",
                category1: "",
                category2: "",
                website: "",
                long_description: "",
                status: "ACTIVE"
            },
            merchant_type_list: [],
            error_message:'',
            error_code: '200',
            inputRules: [
             v => !!v || 'This field is required'
            ]
        }
    },
    async created() {
//        this.$refs.mainForm.reset()
      this.viewMerchant();
    },        
    methods:{
   dlgShow(){
    },
    dlgClose(){
        //console.log(this.respCode);
        
        if(this.respCode == 200){
            this.form.c_code = "";
            this.form.c_name = "";
            this.form.sequence = 0;
            this.show = false;
        }
            
    },                
        async save(){
            this.form.login_id = ''
            if(this.$refs.mainForm.validate()) {
                let title = "New Merchant"
                try{
                    ApMerchantService.insert_merchant(this.form).then((res) => {
                        //console.log(res);
                        this.respCode = res.status;
                        this.$refs.dlgMsg.showDlgBox(true,'success',title,'Success.');
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.respCode = e.response.status;
                        if(e.response.data.error_message)
                            this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                    })
                    ;
                } catch(e) {
                    if(e)
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
                }                  
            }            

        },
        async viewMerchant(){

                let title = "System"
                this.form = {login_id: "",merchant_id: 0}
                try{
                    ApMerchantService.view_merchant(this.form).then((res) => {
                        this.merchant_type_list  = res.data.merchantTypeList
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.respCode = e.response.status;
                        if(e.response.data.error_message)
                            this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                    })
                    ;
                } catch(e) {
                    if(e)
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
                }  
        },
        close(){
            this.dialog = false;
        }
    }
    
}
</script>